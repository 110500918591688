@import 'tailwindcss/base';

@import 'fonts.scss';
@import 'buttons.scss';
@import 'switcher.scss';
@import 'link.scss';
@import 'modal.scss';
@import 'vars.scss';
@import 'container.scss';
@import 'typo.scss';
@import 'text-field.scss';
@import 'layout.scss';
@import 'loader.scss';

html {
  @apply font-primary;
  font-size: 62.5%;
  overscroll-behavior-y: none;
}

body {
  @apply text-black sm:text-base;
  font-size: 1.5rem;
  background-color: #FBFAFC;
}

img {
  image-orientation: none;
}

.no-scrollbar {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

[hidden] {
  display: none !important;
}

@tailwind components;
@tailwind utilities;

a,
button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:active {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

// clear default browser styles for input type="search"
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  display: none;
}