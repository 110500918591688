:root {
  --gradient-primary: linear-gradient(270deg, #8423FF 0%, #C361FF 100%);
  --gradient-light: linear-gradient(270deg, rgba(132, 35, 255, 0.08) 0%, rgba(195, 97, 255, 0.08) 100%);
  --gradient-light-hover: linear-gradient(270deg, rgba(132, 35, 255, 0.18) 0%, rgba(195, 97, 255, 0.18) 100%);
  --gradient-primary-fallback: #8423FF;

  --header-h: 7.5rem;
  @screen lg {
    --header-h: 8.5rem;
  }
  @screen xl {
    --header-h: 10.7rem;
  }

  --sidebar-bg: #161617;
}

.bg-gradient-primary {
  background: var(--gradient-primary);
}

.bg-gradient-light {
  background: var(--gradient-light);
}

.bg-gradient-light-hover {
  background: var(--gradient-light-hover);
}

.text-gradient-primary {
  /* Fallback styles for older browsers */
  color: var(--gradient-primary-fallback);

  /* Override for browsers that support gradient text */
  @supports (-webkit-background-clip: text) or (background-clip: text) {
    background-image: var(--gradient-primary);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

.sidebar-color {
  background: var(--sidebar-bg);
}

.border-progress {
  position: absolute;
  --progress: 0.37;
  --color: theme('colors.primary.DEFAULT');
  @apply -inset-5;
  background: conic-gradient(
                  from 0deg,
                  var(--color) 0deg,
                  var(--color) calc(var(--progress) * 360deg),
                  transparent calc(var(--progress) * 360deg),
                  transparent
  );

  &--error {
    --color: theme('colors.red.DEFAULT');
  }

  border-radius: 1.4rem;
  @screen lg {
    border-radius: 2.6rem;
  }
  &::before {
    content: '';
    position: absolute;
    @apply inset-3;
    background-color: #fafcfa;
    border-radius: 1.2rem;
    @screen lg {
      border-radius: 2.2rem;
    }
  }
}
